import { number, object, string } from 'yup';

export default object({
  name: string().required('required').matches(/^(?!\s+$).*/, 'invalid'),
  email: string().email('invalid').required('required'),
  productId: number().required('required'),
  subject: string().required('required').matches(/^(?!\s+$).*/, 'invalid'),
  description: string().required('required').matches(/^(?!\s+$).*/, 'invalid'),
  recaptcha: string().optional()
});
