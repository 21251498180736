import { Formik, Form, FormikProps } from 'formik';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import Button from '@ux/button';
import Spinner from '@ux/spinner';
import Dropdown from '@ux/dropdown';
import Input from '@ux/text-input';

const { DropdownItem } = Dropdown;

import { useContext } from '../RenderContext';
import Recaptcha from '../Recaptcha';
import contactSchema from './validate';
import util from '../../util';
import { FormEvent } from 'react';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

type Values = {
  name?: string,
  productId?: string,
  email?: string,
  subject?: string,
  description?: string
};

interface InnerFormProps extends FormikProps<Values> {
  intl: IntlShape,
}

let initialSubmitDisabled = true;

const InnerForm = ({
  errors,
  touched,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  handleSubmit,
  isValid,
  intl
}: InnerFormProps) => {
  const {
    settings
  } = useContext();
  const products = settings?.env !== 'production' ? [0] : [
    15,
    7,
    56,
    43,
    3,
    9,
    11,
    6,
    25,
    18,
    8,
    148,
    349,
    57,
    24,
    16,
    190,
    0
  ];
  const handleProductChange = (e: {
    value: string | number
  }) => {
    initialSubmitDisabled = false;
    setFieldValue('productId', e.value);
  };
  const handleProductToggle = (open: boolean) => {
    if (!open) {
      setFieldTouched('productId');
    }
  };
  const handleValueChange = (val: string, name: string) => {
    initialSubmitDisabled = false;
    setFieldValue(name, val, true);
  };
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  return (
    <Form noValidate={ true } onSubmit={ (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setFieldTouched('recaptcha');
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        recaptchaRef.current.execute();
      }
    } }>
      { util.shouldRenderEnglishOnlySupportDisclaimer(settings) && (
        <p className="contactUs-englishOnly"><FormattedMessage id="contactUs.englishOnly" /></p>
      ) }
      <fieldset
        className={ classNames('form-group', { 'has-danger': touched.productId && !!errors.productId }) }
        data-eid="storefront.contact_us.form.product.wrapper"
      >
        <Dropdown
          id="contactUs-form-product"
          type="select"
          disabled={ isSubmitting }
          name="productId"
          onChange={ handleProductChange }
          onToggle={ handleProductToggle }
          invalid={ !!errors.productId }
          label={ <span><FormattedMessage id="contactUs.form.labels.product" />&nbsp;</span> }
          required={ true }
        >
          { products.map(key => (
            <DropdownItem key={ key } value={ key }>
              <FormattedMessage id={ `contactUs.form.products.${key}` } />
            </DropdownItem>
          )) }
        </Dropdown>

        { touched.productId && !!errors.productId && (
          <span className="form-error ux-text ux-text-caption ux-text-feedback-critical" role="alert">
            <FormattedMessage id={ `contactUs.form.${errors.productId}` } />
          </span>
        ) }
      </fieldset>
      <div className="row">
        <div className="col-md-6">
          <Input
            id="contactUs-form-name"
            name="name"
            label={ intl.formatMessage({ id: 'contactUs.form.labels.name' }) }
            required={ true }
            disabled={ isSubmitting }
            onChange={ (val: string) => {
              handleValueChange(val, 'name');
            } }
            data-eid="storefront.contact_us.form.name.input"
            invalid={ !!errors.name }
            errorMessage={ errors.name && intl.formatMessage({ id: `contactUs.form.${errors.name}` }) }
          />
        </div>
        <div className="col-md-6">
          <Input
            id="contactUs-form-email"
            name="email"
            type="email"
            label={ intl.formatMessage({ id: 'contactUs.form.labels.emailAddress' }) }
            disabled={ isSubmitting }
            required={ true }
            onChange={ (val: string) => {
              handleValueChange(val, 'email');
            } }
            data-eid="storefront.contact_us.form.email.input"
            invalid={ !!errors.email }
            errorMessage={ errors.email && intl.formatMessage({ id: `contactUs.form.${errors.email}` }) }
          />
        </div>
      </div>
      <Input
        id="contactUs-form-subject"
        name="subject"
        label={ intl.formatMessage({ id: 'contactUs.form.labels.subject' }) }
        disabled={ isSubmitting }
        required={ true }
        data-eid="storefront.contact_us.form.subject.input"
        invalid={ !!errors.subject }
        onChange={ (val: string) => {
          handleValueChange(val, 'subject');
        } }
        errorMessage={
          errors.subject &&
          intl.formatMessage({ id: `contactUs.form.${errors.subject}` })
        }
      />
      <Input
        id="contactUs-form-description"
        name="description"
        label={ intl.formatMessage({ id: 'contactUs.form.labels.description' }) }
        disabled={ isSubmitting }
        required={ true }
        onChange={ (val: string) => {
          handleValueChange(val, 'description');
        } }
        multiline
        size={ 3 }
        data-eid="storefront.contact_us.form.description.input"
        errorMessage={
          errors.description &&
          intl.formatMessage({ id: `contactUs.form.${errors.description}` })
        }
      />
      <Recaptcha
        locale={ settings.locale }
        siteKey={ settings.recaptcha.siteKey }
        recaptchaRef={ recaptchaRef }
        onChange={ ({ recaptcha }: {
          recaptcha: string | null,
        }) => {
          setFieldValue('recaptcha', recaptcha);
          handleSubmit();
        } }
      />
      <Button
        design="primary"
        type="submit"
        disabled={ initialSubmitDisabled || isSubmitting || !isValid }
        data-eid="storefront.contact_us.form.submit.click"
        text={ (
          <>
            { isSubmitting && (
              <Spinner size="sm" inline shade="dark" />
            ) }
            <FormattedMessage id="contactUs.form.button" />
          </>
        ) }
      />
    </Form>
  );
};

interface PropForms {
  handleSubmit: (values: {}, { setSubmitting }: {
    setSubmitting: (a: boolean) => void;
  }) => void,
  intl: IntlShape,
  shopper?: {
    firstName: string,
    lastName: string,
  }
}

const ContactForm = ({
  handleSubmit,
  shopper,
  intl
}: PropForms) => {
  const initialValues: Values = {};
  if (shopper) {
    initialValues.name = [shopper.firstName, shopper.lastName].join(' ').trim();
  }


  return (
    <div className="contactUs-form">
      <Formik
        initialValues={ initialValues }
        onSubmit={ handleSubmit }
        validationSchema={ contactSchema }
      >
        {(props) => (
          <InnerForm { ...props } intl={ intl }/>
        )}
      </Formik>
    </div>
  );
};

export default injectIntl(ContactForm);
