import codeSigningCertificate from './code-signing-certificate.svg';
import dedicatedIp from './dedicated-ip.svg';
import dedicatedServer from './dedicated-server.svg';
import domainBackorder from './domain-backorder.svg';
import domainRegistration from './domain-registration.svg';
import domainTransfer from './domain-transfer.svg';
import domains from './domains.svg';
import emailMarketing from './email-marketing.svg';
import email from './email.svg';
import hotDeals from './hot-deals.svg';
import hostingCpanel from './hosting-cpanel.svg';
import hostingWindows from './hosting-windows.svg';
import hosting from './hosting.svg';
import onlineCalendar from './online-calendar.svg';
import onlineStorage from './online-storage.svg';
import onlineStore from './online-store.svg';
import premiumDns from './premium-dns.svg';
import reseller from './reseller.svg';
import seo from './seo.svg';
import ssl from './ssl.svg';
import websiteBackup from './website-backup.svg';
import websiteBuilder from './website-builder.svg';
import websiteSecurity from './website-security.svg';
import wordpress from './wordpress.svg';

export {
  codeSigningCertificate,
  dedicatedIp,
  dedicatedServer,
  domainBackorder,
  domainRegistration,
  domainTransfer,
  domains,
  emailMarketing,
  email,
  hotDeals,
  hostingCpanel,
  hostingWindows,
  hosting,
  onlineCalendar,
  onlineStorage,
  onlineStore,
  premiumDns,
  reseller,
  seo,
  ssl,
  websiteBackup,
  websiteBuilder,
  websiteSecurity,
  wordpress
};
